module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Dan Dean's Website","short_name":"Dean Dean","start_url":"/","background_color":"blue","theme_color":"blue","display":"standalone","icon":"src/img/favicon.png","icons":[{"src":"/favicons/large-512x512.png","sizes":"512x512","type":"image/png"},{"src":"/favicons/chrome-192x192.png","sizes":"192x192","type":"image/png"},{"src":"/favicons/safari-180x180.png","sizes":"180x180","type":"image/png"},{"src":"/favicons/common-32x32.png","sizes":"32x32","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"6e3e4d3c0854493b3d2188c00da344aa"},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":800,"linkImagesToOriginal":false,"disableBgImageOnAlpha":true,"backgroundColor":"transparent","showCaptions":false,"markdownCaptions":false,"quality":50,"withWebp":false,"withAvif":false,"loading":"lazy","decoding":"async","disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
